import {
  IHeaderLink,
  IHeaderLinkChild,
  ILink,
} from "apps/website/components/base/Button/Button.map";
import {
  IStoryBlokLink,
  IStoryBlokCta,
  IStoryBlokHeaderLink,
  IStoryBlokFooterLink,
} from "apps/website/components/page/api.types";
import {
  STORYBLOK_PAGE_ROOT,
  STORYBLOK_ARTICLES_PRETTY_ROOT,
  STORYBLOK_ARTICLES_ROOT,
  STORYBLOK_PRODUCTS_PRETTY_ROOT,
  STORYBLOK_PRODUCTS_ROOT,
  STORYBLOK_FLOWS_ROOT,
  STORYBLOK_FLOWS_PRETTY_ROOT,
} from "apps/website/constants/storyblok";

import { getStoryBlokImage } from "./image";

export const getStoryBlokLink = (link: IStoryBlokLink) => redirectToCorrectInternalPath(link.url);

export const getStoryBlokCta = (cta: IStoryBlokCta): ILink | undefined => (cta ? {
  id: cta._uid,
  color: cta.color,
  design: cta.design,
  name: cta.name,
  to: redirectToCorrectInternalPath(cta.link.url),
  testId: cta.test_id,
  keepQueryParams: cta.keep_query_params,
} : undefined);

export const getStoryBlokHeaderLinkChildren = (links: IStoryBlokHeaderLink[]): IHeaderLinkChild[] => (
  links.map((link) => {
    const headerLink = getStoryBlokHeaderLink(link);
    return {
      ...getStoryBlokHeaderLink(link),
      id: headerLink?.id ?? "",
      name: headerLink?.name ?? "",
      to: headerLink?.to ?? "",
      image: getStoryBlokImage(link.image, true, 310),
      tag: link.tag,
      seeMoreText: link.see_more_text,
    };
  }));

export const getStoryBlokHeaderLink = (link: IStoryBlokHeaderLink): IHeaderLink | undefined => (link ? {
  id: link._uid,
  name: link.name,
  to: link.link.url,
  children: getStoryBlokHeaderLinkChildren(link.children ?? []),
} : undefined);

export const getStoryBlokPageWrapperLink =
  (link: IStoryBlokHeaderLink | IStoryBlokFooterLink): ILink | undefined => (link ? {
    id: link._uid,
    name: link.name,
    to: link.link.url,
  } : undefined);

export const getStoryBlokCtas = (ctas: IStoryBlokCta[]) => ctas.map((cta) => getStoryBlokCta(cta))
  .filter((c) => !!c).map((co) => co as ILink);
export const getStoryBlokPageWrapperLinks = (links: IStoryBlokHeaderLink[] | IStoryBlokFooterLink[]) => (
  links.map((link) => getStoryBlokPageWrapperLink(link))
);
export const getStoryBlokPageHeaderLinks = (links: IStoryBlokHeaderLink[]) => (
  links.map((link) => getStoryBlokHeaderLink(link))
);

export const redirectToCorrectInternalPath = (path: string) => {

  const pathMap = {
    [STORYBLOK_PAGE_ROOT]: "",
    [STORYBLOK_ARTICLES_ROOT]: STORYBLOK_ARTICLES_PRETTY_ROOT,
    [STORYBLOK_PRODUCTS_ROOT]: STORYBLOK_PRODUCTS_PRETTY_ROOT,
    [STORYBLOK_FLOWS_ROOT]: STORYBLOK_FLOWS_PRETTY_ROOT,
  };

  const pathPrefix = Object.keys(pathMap).find((key) => path.startsWith(`/${key}`));

  if (!pathPrefix || !(pathPrefix in pathMap)) return path;
  return path.replace(`/${pathPrefix}`, `/${pathMap[pathPrefix as keyof (typeof pathMap)]}`);
};
